import ModalContainerComponent from "./ModalContainerComponent"

class ModalContainer {
  constructor(vm) {
    this._root = vm.$root
  }

  launch(modal, options = {}) {
    // console.log("launch from index")
    return new Promise((resolve, reject) => {
      this._root.$emit("bmc_launch", modal, { resolve, reject }, options)
    })
  }

  close() {
    this._root.$emit("bmc_close")
  }
}

export default {
  install(Vue) {
    if (Vue.$_modal_container_installed) return
    Vue.$_modal_container_installed = true

    Vue.mixin({
      beforeCreate() {
        this.$modal_container = new ModalContainer(this)
      }
    })

    Vue.component("ModalContainer", ModalContainerComponent)
  }
}
