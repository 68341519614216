<template>
  <div
    class="bp-modal-transition"
    :class="`bp-modal-transition--${currentTransition[0]}`"
  >
    <transition
      :name="currentTransition[0]"
      :duration="currentTransition[1]"
      appear
      @after-leave="() => $emit('after-leave')"
    >
      <div
        v-if="condition"
        class="bp-modal-transition__container"
        :class="`bp-modal-transition__container--${currentTransition[0]}`"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>
<script>
import styles from "@/styles/settings.scss"
import { mapState } from "vuex"

export default {
  props: {
    transitions: Object,
    condition: Boolean
  },
  data: () => ({
    currentActiveBreakpoint: null
  }),
  created() {
    this.setCurrentActiveBreakpoint()
  },
  methods: {
    setCurrentActiveBreakpoint() {
      let keys = Object.keys(this.transitions)

      let tempActiveBreakpoint = null

      for (let i = 0; i < keys.length; i++) {
        let bpToCheck = {
          breakpoint: keys[i],
          size: Number(styles["BREAKPOINT_" + keys[i]])
        }

        // first check that the breakpoint is smaller than current width
        // if it is, then check if it's bigger than the existing temp breakpoint
        // NB - this is the inverse of the bp-down approach of the ImageComponent
        tempActiveBreakpoint =
          bpToCheck.size < this.currentWidth &&
          (!tempActiveBreakpoint || bpToCheck.size > tempActiveBreakpoint.size)
            ? bpToCheck
            : tempActiveBreakpoint
      }

      this.currentActiveBreakpoint = tempActiveBreakpoint
    }
  },
  computed: {
    ...mapState("breakpoints", ["currentWidth"]),
    currentTransition() {
      return this.transitions[this.currentActiveBreakpoint.breakpoint]
    }
  },

  watch: {
    currentWidth() {
      this.setCurrentActiveBreakpoint()
    }
  }
}
</script>
<style lang="scss">
.bp-modal-transition {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &--slide-up {
    justify-content: flex-end;
  }

  &--center-slide-up {
    justify-content: center;
  }

  &__container {
    position: relative;
    pointer-events: none;

    &--slide-up {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: stretch;
    }

    &--center-slide-up {
      width: 80%;
      max-width: 650px;

      // @include media-breakpoint-up(lg) {
      //   max-width: 740px;
      // }
    }

    &--center-zoom {
      max-width: 900px;
    }

    &--zoom-fill {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.slide-up-enter-active,
.slide-up-leave-active,
.center-slide-up-enter-active,
.center-slide-up-leave-active {
  transition: all 0.4s ease;
}
.slide-up-enter,
.slide-up-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

.center-slide-up-enter,
.center-slide-up-leave-to {
  opacity: 0;
  transform: translateY(50%);
}

.center-zoom-enter-active,
.center-zoom-leave-active,
.zoom-fill-enter-active,
.zoom-fill-leave-active {
  transition: all 0.3s ease;
  transform-origin: center;
}

.center-zoom-enter,
.center-zoom-leave-to,
.zoom-fill-enter,
.zoom-fill-leave-to {
  opacity: 0;
  transform: scale(0.75);
}
</style>
