<template>
  <div>
    <transition
      name="modalfade"
      appear
      :duration="300"
      @after-enter="$emit('launch')"
      @after-leave="handleContainerClosed()"
    >
      <div
        class="bp-modal-container noselect"
        :style="{ height: `${viewportHeight}px` }"
        v-if="modalOpen"
        ref="modal"
        role="dialog"
        aria-modal="true"
        :aria-label="''"
        @keyup.esc="handleContainerClosing"
        @keydown.tab="handleTabInput"
      >
        <div
          v-if="!options.notice"
          class="bp-modal-container__backdrop noselect"
          @click="handleContainerClosing"
        ></div>
        <div class="bp-modal-container__inner">
          <div class="bp-modal-container__holder">
            <div ref="content" class="bp-modal-container__content">
              <ModalTransition
                :transitions="component.transitions"
                :condition="!modalClosing"
                @after-leave="handleComponentAfterLeave"
              >
                <component
                  :is="component"
                  ref="component"
                  v-bind="props"
                  v-on="listeners"
                  :notice="!!options.notice"
                  :resolve="options.resolve"
                  :reject="options.reject"
                  @close="handleComponentClosed"
                  @hook:mounted="handleComponentMounted"
                ></component>
              </ModalTransition>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
// import {
//   disableBodyScroll,
//   enableBodyScroll
//   /* clearAllBodyScrollLocks */
// } from "body-scroll-lock"
// import { lockScroll, unlockScroll } from "@/helpers/ScrollLock"
import { lockScroll, unlockScroll } from "@/helpers/ScrollLock"

import ModalTransition from "@/plugins/ModalContainer/ModalTransition"
import { mapMutations } from "vuex"

const focusableElements =
  'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'

export default {
  name: "ModalContainer",
  data() {
    return {
      modalOpen: false,
      modalClosing: false,
      component: null,
      resolve: null,
      reject: null,
      lastFocusedElement: null,
      options: {},
      modalQueue: [],
      viewportHeight: 0
    }
  },

  components: {
    ModalTransition
  },

  created() {
    this.$root.$on("bmc_launch", this.launch)
    this.$root.$on("bmc_close", this.close)
    visualViewport.addEventListener("resize", this.handleViewportResize)
    this.handleViewportResize()
  },

  beforeDestroy() {
    visualViewport.removeEventListener("resize", this.handleViewportResize)
  },

  methods: {
    ...mapMutations("ui", ["SET_NAV_ACTIVE_ELEMENT"]),

    launch(component, { resolve, reject }, options) {
      //if there is already an active modal, add this one to the queue (if there isn't one of its kind in the queue already).
      this.modalQueue.push({ component, options, resolve, reject })
      if (
        !this.modalQueue
          .map(({ component }) => component.name)
          .includes(component.name)
      ) {
        this.modalQueue.push({ component, options, resolve, reject })
      }
      const { activeElement } = document
      if (activeElement) {
        this.lastFocusedElement = activeElement
      }
      const nextInQueue = this.modalQueue[0]
      setTimeout(() => {
        this.initComponent(nextInQueue)
      }, Number(nextInQueue.options.delay) || 0)
    },

    initComponent({ component, options, resolve, reject }) {
      if (!options.notice) {
        this.SET_NAV_ACTIVE_ELEMENT(null)
        // this.$nextTick(() => {
        //   let _count = 0
        //   let _looper = setInterval(() => {
        //     _count++
        //     if (this.$refs["component"]) {
        //       clearInterval(_looper)
        //       lockScroll(this.$refs["component"])
        //     }
        //     if (_count > 100) {
        //       this.debug.log(
        //         "FAILED TO LOCK SCROLLING, no target element found - timeout"
        //       )
        //     }
        //   }, 2)

        //   // this.$nextTick(() => {
        //   //   if (this.$refs["component"]) {
        //   //     lockScroll(this.$refs["component"])
        //   //   } else {
        //   //     this.debug.log(
        //   //       "FAILED TO LOCK SCROLLING, no target element found"
        //   //     )
        //   //   }
        //   // })

        //   // let _count = 0
        //   // let _looper = setInterval(() => {
        //   //   _count++
        //   //   if (this.$refs["component"]) {
        //   //     clearInterval(_looper)
        //   //     lockScroll(this.$refs["component"])
        //   //   }
        //   // }, 2)
        //   // lockScroll(this.$refs["component"])
        //   // disableBodyScroll(this.$refs["scrolltarget"])
        // })
      }
      this.modalOpen = true
      this.modalClosing = false
      this.component = component
      this.resolve = resolve
      this.reject = reject
      this.options = { ...options, resolve, reject }
    },

    close() {
      this.modalQueue.shift()
      if (this.modalQueue.length == 0) {
        const { lastFocusedElement } = this
        if (lastFocusedElement) {
          lastFocusedElement.focus()
        }
        this.modalOpen = false
        // enableBodyScroll(this.$refs["scrolltarget"])
        // unlockScroll(this.$refs["component"])
      } else {
        const nextInQueue = this.modalQueue[0]
        setTimeout(() => {
          this.initComponent(nextInQueue)
        }, Number(nextInQueue.options.delay) || 0)
      }
    },

    getFocusableElements() {
      const { modal } = this.$refs
      const elements = modal && modal.querySelectorAll(focusableElements)
      return (
        (elements &&
          Array.prototype.slice
            .call(elements)
            .filter(element => element.offsetParent !== null)) ||
        []
      )
    },

    handleTabInput(e) {
      const focusableElements = this.getFocusableElements()
      if (!focusableElements.length) return
      const firstFocusableElement = focusableElements[0]
      const lastFocusedElement = focusableElements[focusableElements.length - 1]
      let element
      if (document.activeElement === firstFocusableElement && e.shiftKey) {
        element = lastFocusedElement
      } else if (document.activeElement === lastFocusedElement && !e.shiftKey) {
        element = firstFocusableElement
      }
      if (element) {
        e.preventDefault()
        element.focus()
      }
    },

    handleComponentMounted(/* e */) {
      if (!this.options.notice) {
        lockScroll(this.$refs["component"])
      }
      const focusableElements = this.getFocusableElements()
      if (focusableElements.length) {
        focusableElements[0].focus()
      }
    },

    handleComponentAfterLeave() {
      this.close()
    },

    handleComponentClosed() {
      this.modalClosing = true
    },

    handleContainerClosing() {
      if (!this.options.priority) {
        this.reject()
        this.modalClosing = true
      }
    },

    handleContainerClosed() {
      this.modalClosing = false
    },

    handleViewportResize() {
      this.viewportHeight = visualViewport.height
    }
  },

  computed: {
    props() {
      return this.options.props || {}
    },
    listeners() {
      return this.options.listeners || {}
    }
  },

  watch: {
    modalClosing(newVal) {
      if (newVal) {
        if (!this.options.notice) {
          unlockScroll(this.$refs["component"])
        }
      }
    }
  }
}
</script>
<style lang="scss">
.bp-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  // height: 100%;
  z-index: 10;
  pointer-events: none;

  &__backdrop,
  &__inner {
    position: absolute;
    pointer-events: all;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &__backdrop {
    cursor: pointer;
    background-color: rgba(getcolour(bleach_black), 0.25);
    backdrop-filter: contrast(0.5);

    // &--disabled {
    //   cursor: unset;
    //   pointer-events: none;
    // }
  }

  &__inner {
    pointer-events: none;
  }
}

.modalfade-enter-active,
.modalfade-leave-active {
  .bp-modal-container {
    &__backdrop {
      transition: opacity 0.3s;
    }
    &__inner {
      transition: opacity 0s;
    }
  }
}
.modalfade-enter,
.modalfade-leave-to {
  .bp-modal-container__backdrop {
    opacity: 0;
  }
}
</style>
