export default {
  props: ["resolve", "reject"],

  mounted() {},

  methods: {
    emitClose() {
      this.$emit("close")
    }
  }
}
